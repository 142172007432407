import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import estoreSlice from "./estoreSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    estore: estoreSlice,
  },
});
