import React from "react";
import { LogoutOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logoutUser } from "../../reducers/userSlice";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const logout = () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate("/");
  };

  const menuStyle = {
    mainContainer: {
      backgroundColor: "#009A57",
      height: "30px",
      width: "100%",
      color: "#ffffff",
      fontSize: 24,
      padding: "15px 20px",
      position: "fixed",
      zIndex: 1,
    },
  };

  return (
    <div style={menuStyle.mainContainer}>
      <strong>Clavstore Reseller</strong>
      {user._id && (
        <div
          onClick={() => logout()}
          style={{ float: "right", marginRight: 50, cursor: "pointer" }}
        >
          <LogoutOutlined /> {!isMobile && <>Logout</>}
        </div>
      )}
    </div>
  );
};

export default Header;
